import { graphql } from 'gatsby'
import React from 'react'
import Hero from "../components/Hero"
import SellingPoints from '../components/SellingPoints'
import Situations from '../components/Situations'
import SecondaryCTA from "../components/SecondaryCTA"
import Authorities from '../components/Authorities'
import Layout from '../components/Layout/Layout'


const HomePage = ({ data, pageContext }) => {
    const homepage = data.contentfulHomepage

    return <Layout pageContext={pageContext}>
        <Hero homepage={homepage} />
        <SellingPoints homepage={homepage} />
        <Situations homepage={homepage} />
        <SecondaryCTA homepage={homepage} />
        <Authorities homepage={homepage} />
    </Layout>
}

export default HomePage

export const pageQuery = graphql`
    query IndexPageContent($locale: String!) {
        contentfulHomepage(node_locale: {eq: $locale}) {
            headline {
                childMarkdownRemark { html }
            }
            intro {
                intro
            }
            departureAirport
            departureAirportPlaceholder
            destinationAirport
            destinationAirportPlaceholder
            callToAction
            callToActionDescription {
                childMarkdownRemark { html }
            }
            errorSelectAirport
            errorSameAirport
            sellingPoints {
                sellingPoints
            }
            sellingPointsLead
            sellingPointsHeadline
            sellingPointsCopy {
                sellingPointsCopy
            }
            sellingPoints2Lead
            sellingPoints2Headline
            sellingPointsSteps {
                title
                body {
                    body
                }
            }
            situationsTitle
            situations {
                slug
                title
                body {
                    childMarkdownRemark { html }
                }
            }
            secondaryCtaTitle
            secondaryCtaLine1 {
                childMarkdownRemark { html }
            }
            secondaryCtaLine2 {
                childMarkdownRemark { html }
            }
            secondaryCtaLine3 {
                childMarkdownRemark { html }
            }
            secondaryCtaButtonLink
            secondaryCtaButtonText
            authorities1
            authorities2
        }
    }
`
