import _ from 'lodash'
import React from 'react'

import {Container, Row, Col} from 'reactstrap'

import './style.scss'
import ClaimFormStub from "../ClaimFormStub/ClaimFormStub";


export default ({ homepage }) => (
    <div className="hero-container" >
        <div className="hero-bg">
            <Container>

                <h1 dangerouslySetInnerHTML={{ __html: homepage.headline.childMarkdownRemark.html }} />

                <Row>
                    <Col md="8">
                        <p className="more">
                            {homepage.intro.intro}
                        </p>

                    </Col>
                </Row>

                <ClaimFormStub homepage={homepage} />

            </Container>
        </div>

        <div className="hero-bg2 points">
            <Container>
                <Row>
                    {_.map(homepage.sellingPoints.sellingPoints.split('\n'), line => (
                        <Col sm="4" className="position-relative"><p>{line}</p></Col>
                    ))}
                </Row>
            </Container>
        </div>
    </div>
)
