import _ from 'lodash'
import React from 'react'
import {Container, Row, Col} from 'reactstrap'

import './style.scss'


export default class SellingPoints extends React.Component {
    render () {
        const { homepage } = this.props
        return <Container className="selling-points">

            <Row className="big-point1">
                <Col sm="4">
                    <div className="graphic"/>
                </Col>

                <Col sm="8">
                    <div className="lead_">
                        {homepage.sellingPointsLead}
                    </div>
                    <div className="headline">
                        {homepage.sellingPointsHeadline}
                    </div>
                    <p className="copy">
                        {homepage.sellingPointsCopy.sellingPointsCopy}
                    </p>
                </Col>
            </Row>

            <Row className="big-point2">
                <Col sm="4" className="order-sm-2">
                    <div className="graphic"/>
                </Col>

                <Col sm="8" className="order-sm-1">
                    <div className="lead_">
                        {homepage.sellingPoints2Lead}
                    </div>
                    <div className="headline">
                        {homepage.sellingPoints2Headline}
                    </div>
                </Col>
            </Row>

            <Row className="steps">
                {_.map(homepage.sellingPointsSteps, (step, index) => (
                    <Col sm="4" className={`step${index + 1}`} key={step.title}>
                        <div className="step">
                            <div className="title">{step.title}</div>
                            <p>
                                {step.body.body}
                            </p>
                        </div>
                    </Col>
                ))}
            </Row>
        </Container>
    }
}
