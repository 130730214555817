import React from 'react'
import Link from 'gatsby-link'
import {Container, Row, Col, Button} from 'reactstrap'

import './style.scss'


export default class SecondaryCTA extends React.Component {
    render () {
        const { homepage } = this.props
        return <div className="secondary-cta">
            <Container>
                <Row>
                    <Col xs="12">
                        <div className="title">{homepage.secondaryCtaTitle}</div>

                        <div className="line1" dangerouslySetInnerHTML={{ __html: homepage.secondaryCtaLine1.childMarkdownRemark.html }} />
                        <div className="line2" dangerouslySetInnerHTML={{ __html: homepage.secondaryCtaLine2.childMarkdownRemark.html }} />

                        <Link to={homepage.secondaryCtaButtonLink}>
                            <Button>{homepage.secondaryCtaButtonText}</Button>
                        </Link>

                        <div className="line3" dangerouslySetInnerHTML={{ __html: homepage.secondaryCtaLine3.childMarkdownRemark.html }} />
                    </Col>
               </Row>
            </Container>
        </div>
    }
}
