import React from 'react'
import {Container, Row, Col} from 'reactstrap'

import './style.scss'


export default class Authorities extends React.Component {
    render () {
        const { homepage } = this.props
        return <div className="authorities">
            <Container>
                <Row noGutters={true}>
                    <Col sm="6" className="one">
                        <div className="graphic"></div>
                        <p>{homepage.authorities1}</p>
                    </Col>

                    <Col sm="6" className="two">
                        <div className="graphic"></div>
                        <p>{homepage.authorities2}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    }
}
