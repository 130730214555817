import React from 'react'

import {Container, Row, Col} from 'reactstrap'

import './style.scss'


export default class Situations extends React.Component {

    constructor (props) {
        super(props)

        this.state = {
            selected: 'overbooked'
        }
    }

    getSelectorClasses (slug, extraClasses = []) {
        let classes = extraClasses.concat([slug])

        if (slug === this.state.selected) {
            classes.push('selected')
        }

        return classes.join(' ')
    }

    onItemSelect (slug) {
        this.setState({selected: slug})
    }

    render () {
        const { homepage } = this.props

        return <div className="situations">
            <Container>
                <div className="title">{homepage.situationsTitle}</div>
                <Row>
                    <Col sm="4" className="selector">
                        {homepage.situations.map(
                            situation => <div
                                className={this.getSelectorClasses(situation.slug, ['selector-item'])}
                                onClick={() => this.onItemSelect(situation.slug)}
                                key={situation.slug}
                            >
                                {situation.title}
                            </div>)
                        }
                    </Col>

                    <Col sm="8" className="display">
                        {homepage.situations.map(situation => (
                            <div
                                className={this.getSelectorClasses(situation.slug)}
                                dangerouslySetInnerHTML={{ __html: situation.body.childMarkdownRemark.html }}
                                key={situation.slug}
                            />
                        ))}
                    </Col>

                </Row>
            </Container>
        </div>
    }
}
