import * as React from 'react'
import { t } from 'ttag'
import {Row, Col, Label, Button, Alert} from 'reactstrap'
import { navigate } from 'gatsby-link'

import AirportAutocomplete from "../Forms/AirportAutocomplete";

type Props = {
    homepage: {
        errorSelectAirport: string
        errorSameAirport: string
        departureAirport: string
        departureAirportPlaceholder: string
        destinationAirport: string
        destinationAirportPlaceholder: string
        callToAction: string
        callToActionDescription: {
            childMarkdownRemark: { html: string }
        }
        secondaryCtaButtonLink: string
    }
}

export default class ClaimFormStub extends React.Component<Props> {
    state = {
        departureAirport: {
            value: null,
            dirty: false,
            error: null,
        },
        destinationAirport: {
            value: null,
            dirty: false,
            error: null,
        },
        formValid: true,
    }

    onDepartureAirportChange = (selection) => {
        let newValue = selection.length ? selection[0] : null

        this.setState({
            departureAirport: {
                ...this.state.departureAirport,
                value: newValue,
                dirty: true,
            }
        })
    }

    onDestinationAirportChange = (selection) => {
        let newValue = selection.length ? selection[0] : null

        this.setState({
            destinationAirport: {
                ...this.state.destinationAirport,
                value: newValue,
                dirty: true,
            }
        })
    }

    validate () {
        const { homepage } = this.props
        const {departureAirport, destinationAirport} = this.state
        let valid = true
        let isNotEUMessage = t`Compensation can not be claimed when both airports are outside the EU`

        if (!departureAirport.value) {
            valid = false
            this.setState({
                departureAirport: {...departureAirport, error: homepage.errorSelectAirport}
            })
        }

        if (!destinationAirport.value) {
            valid = false
            this.setState({
                destinationAirport: {...destinationAirport, error: homepage.errorSelectAirport}
            })
        }

        if (destinationAirport.value
            && departureAirport.value
            && departureAirport.value.iata_code == destinationAirport.value.iata_code) {

            valid = false
            this.setState({
                destinationAirport: {
                    ...destinationAirport,
                    error: homepage.errorSameAirport,
                }
            })
        }

        if (destinationAirport.value 
            && departureAirport.value 
            && !destinationAirport.value.is_eu 
            && !departureAirport.value.is_eu) {

            valid = false
            this.setState({
                destinationAirport: {
                    ...destinationAirport,
                    error: isNotEUMessage,
                }
            })

            this.setState({
                departureAirport: {
                    ...departureAirport,
                    error: isNotEUMessage,
                }
            })
        }

        return valid
    }

    onSubmitClick = (e) => {
        e.preventDefault()
        if (this.validate()) {
            this.openForm()
        }
    }

    openForm () {
        let fromCode = this.state.departureAirport.value.iata_code
        let toCode = this.state.destinationAirport.value.iata_code
        // navigate(`${this.props.homepage.secondaryCtaButtonLink}?from=${fromCode}&to=${toCode}`)
        window.location.assign(`${this.props.homepage.secondaryCtaButtonLink}?from=${fromCode}&to=${toCode}`)
    }

    render () {
        const { homepage } = this.props
        return <Row>
            <Col className="mini-form d-lg-flex" lg="12" >
                <div className="source-airport">
                    <Label>{homepage.departureAirport}</Label>

                    <AirportAutocomplete
                        id="departure-airport"
                        name="departure-airport"
                        placeholder={homepage.departureAirportPlaceholder}
                        onChange={this.onDepartureAirportChange}
                    />

                    { this.state.departureAirport.error ?
                        <Alert color="danger">{this.state.departureAirport.error}</Alert> : null
                    }

                </div>

                <div className="divider d-none d-lg-block"></div>

                <div className="dest-airport">
                    <Label>{homepage.destinationAirport}</Label>

                    <AirportAutocomplete
                        id="destination-airport"
                        name="destination-airport"
                        placeholder={homepage.destinationAirportPlaceholder}
                        onChange={this.onDestinationAirportChange}
                    />

                    { this.state.destinationAirport.error ?
                        <Alert color="danger">{this.state.destinationAirport.error}</Alert> : null
                    }

                </div>

                <div className="submit-container ms-lg-4">
                    <Button onClick={this.onSubmitClick}>{homepage.callToAction}</Button>
                    <Label dangerouslySetInnerHTML={{ __html: homepage.callToActionDescription.childMarkdownRemark.html }}></Label>
                </div>
            </Col>
        </Row>
    }
}
